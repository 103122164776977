var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-snackbar',{attrs:{"vertical":_vm.snack.vertical,"color":_vm.snack.color,"right":true,"top":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('menu.cerrar'))+" ")])]}}]),model:{value:(_vm.snack.snackbar),callback:function ($$v) {_vm.$set(_vm.snack, "snackbar", $$v)},expression:"snack.snackbar"}},[_vm._v(" "+_vm._s(_vm.snack.txt)+" ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"o-modal-rangos"},[_c('v-card-title',{staticStyle:{"padding-right":"25px !important","padding-bottom":"0px !important","padding-left":"32px !important"}},[_c('p',{staticClass:"bold mb-0 txt-enasyo"},[_vm._v(_vm._s(_vm.$t('ensayos.txt_param_condition')))])]),_c('v-card-text',[_c('v-container',{staticClass:"content-codition"},[[(_vm.renderTable)?_c('v-data-table',{key:"dataReloaded",ref:"dataTable",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.form_data.data_ensayo.parametrosCondicion,"show-expand":true,"expanded":_vm.expanded,"hide-default-footer":true,"disable-pagination":"","item-key":"nombre"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.cod_pat",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"dense":"","outlined":"","items":_vm.nivelparam,"placeholder":_vm.$t('ensayos.classifitacion'),"item-text":"nombre","item-value":"id"},model:{value:(item.clasificacionid),callback:function ($$v) {_vm.$set(item, "clasificacionid", $$v)},expression:"item.clasificacionid"}})]}},{key:"item.ncorto",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"dense":"","outlined":"","items":_vm.nivels,"placeholder":_vm.$t('ensayos.RiskLevel'),"item-text":"nivel","item-value":"valor"},model:{value:(item.nivel),callback:function ($$v) {_vm.$set(item, "nivel", $$v)},expression:"item.nivel"}})]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [(_vm.form_data.data_ensayo.tipoRes == 1)?_c('div',{staticClass:"c-pointer color-azul text-right mx-2",on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},[_c('v-icon',{staticClass:"color-azul"},[_vm._v(_vm._s(isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])],1):_vm._e()]}},(_vm.renderTable)?{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"p-0",attrs:{"colspan":headers.length}},[(_vm.renderTable)?_c('formRangos',{ref:"formRangos",attrs:{"parametro":item}}):_vm._e()],1)]}}:null],null,true)}):_vm._e()]],2)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-auto o-btn-ensayos-cancelar c-btn",attrs:{"color":"#007AFF","outlined":""},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v(" "+_vm._s(_vm.$t('ensayos.cancelar'))+" ")]),_c('v-btn',{staticClass:"o-btn-ensayos-guardar c-btn c-btn--blue bold",attrs:{"outlined":""},on:{"click":function($event){return _vm.Confirmanivel()}}},[_vm._v(" "+_vm._s(_vm.$t('ensayos.guardar'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }