<style lang="scss">
  td.pl-0 {
    border-width: 0 !important;
  }
  .o-modal-rangos{
    .content-codition{
      max-height: initial;
    }
  }
</style>
<template>
<!-- eslint-disable -->
    <v-row justify="center">

      <v-snackbar
      v-model="snack.snackbar"
      :vertical="snack.vertical"
      :color="snack.color"
      :right="true"
      :top="true"
      >
        {{ snack.txt }}

        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snack.snackbar = false"
          >
            {{ $t('menu.cerrar') }}
          </v-btn>
        </template>
      </v-snackbar>

        <v-dialog
        v-model="dialog"
        persistent
        max-width="800px"
        >
        <v-card class="o-modal-rangos">
            <v-card-title
                style="
                    padding-right: 25px !important;
                    padding-bottom: 0px !important;
                    padding-left: 32px !important;"
            >
            <p class="bold mb-0 txt-enasyo">{{ $t('ensayos.txt_param_condition')}}</p>
            </v-card-title>
            <v-card-text>
                <!-- <v-row class="ml-mr-1  space-between b-top-bottom"> -->
                    <!-- 
                      <v-col class="pl-0 text-left py-2">
                        <p class="fs16 bold mb-0 pl-3">{{ $t('ensayos.t_param') }}</p>
                    </v-col>
                    <v-col class="text-center py-2">
                        <p class="fs16 bold mb-0 pl-0">{{ $t('ensayos.classifitacion') }}</p>
                    </v-col>
                    <v-col class="text-center  py-2">
                        <p class="fs16 bold mb-0 pl-0">{{ $t('ensayos.RiskLevel') }}</p>
                    </v-col>
                     -->
                    <!-- <v-col v-if ="form_data.data_ensayo.tipoRes == 1" class="text-center  py-2">
                      <p class="fs16 bold mb-0 pl-0">Options</p>
                    </v-col> 
                    -->
                <!-- </v-row> -->
                <v-container class="content-codition">
                  <template>
                    <v-data-table
                      :headers="headers"
                      :items="form_data.data_ensayo.parametrosCondicion"
                      ref="dataTable"
                      :show-expand="true"
                      :expanded.sync="expanded"
                      :hide-default-footer="true"
                      disable-pagination
                      item-key="nombre"
                      key="dataReloaded"
                      class="elevation-1"
                      v-if="renderTable"
                    >
                      <template v-slot:item.cod_pat="{ item }">
                        <v-select
                          dense
                          outlined
                          :items="nivelparam"
                          :placeholder="$t('ensayos.classifitacion')"
                          item-text="nombre"
                          item-value="id"
                          v-model="item.clasificacionid"
                        ></v-select>
                      </template>
              
                      <template v-slot:item.ncorto="{ item }">
                        <v-select
                          dense
                          outlined
                          :items="nivels"
                          :placeholder="$t('ensayos.RiskLevel')"
                          item-text="nivel"
                          item-value="valor"
                          v-model="item.nivel"
                        ></v-select>
                      </template>

                      <template v-slot:item.data-table-expand="{item, isExpanded}">
                        <div class="c-pointer color-azul text-right mx-2" v-if="form_data.data_ensayo.tipoRes == 1" @click="handleExpansion(item, isExpanded)">
                            <!-- <span>{{$t('lang.dashboard.ver_detalle')}}</span> -->
                            <v-icon class="color-azul">{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }} </v-icon>
                        </div>
                      </template>

                      <template v-slot:expanded-item="{ headers, item }" v-if="renderTable">
                          <td :colspan="headers.length" class="p-0">
                            <formRangos v-if="renderTable" :parametro="item" ref="formRangos"></formRangos>
                          </td>
                      </template>
                    </v-data-table>
                  </template>
                </v-container>
            </v-card-text>
            <v-card-actions>
              <!--
                <v-spacer></v-spacer>
              -->
                <!--<v-btn
                    class="ma-2 c-btn c-btn--primary"
                    color="indigo darken-3"
                    outlined
                    text
                    @click="Confirmanivel()"
                >
                {{ $t('ensayos.guardar') }}
                </v-btn>-->
                <v-btn
                  class="ml-auto o-btn-ensayos-cancelar c-btn"
                  color="#007AFF"
                  outlined
                   @click="cancelar()">
                    {{ $t('ensayos.cancelar') }}
                </v-btn>
                <v-btn
                    class="o-btn-ensayos-guardar c-btn c-btn--blue bold"
                    outlined
                     @click="Confirmanivel()">
                    {{ $t('ensayos.guardar') }}
                </v-btn>
                </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
/* eslint-disable */
import { mapState } from 'vuex';
import i18n from '../../plugins/il8n';

export default {
  components: {
    formRangos: () => import('./formRangos.vue'),
  },
  data: () => ({
    dialog: false,
    renderTable: true,
    parametrosSeelcted: [],
    dataReloaded: 0,
    nivelParametros: [
      {
        id: 1,
        nombre: i18n.t('ensayo.pathogeno'),
        nivel: 1,
      },
      {
        id: 2,
        nombre: 'Deteriorant',
        nivel: 2,
      },
      {
        id: 3,
        nombre: 'Indicador',
        nivel: 3,
      },
    ],
    nivel: [
      { id: 1, nivel: 'Bajo', valor: 0 },
      { id: 2, nivel: 'Alto', valor: 1 },
    ],
    snack: {
      snackbar: false,
      txt: null,
      color: 'red',
      error: false,
      x: null,
      y: 'top',
    },
    expanded: [],
    singleExpand: false,
    headers: [],
  }),
  computed: {
    nivels() {
      const nivel = [
        { id: 1, nivel: i18n.t('ensayos.bajo'), valor: 0 },
        { id: 2, nivel: i18n.t('ensayos.alto'), valor: 1 },
      ];
      return nivel;
    },
    nivelparam() {
      const nivelParametros = [
        {
          id: 1,
          nombre: i18n.t('ensayos.pathogeno'),
          nivel: 1,
        },
        {
          id: 2,
          nombre: i18n.t('ensayos.deteriorante'),
          nivel: 2,
        },
        {
          id: 3,
          nombre: i18n.t('ensayos.indicador'),
          nivel: 3,
        },
      ];
      return nivelParametros;
    },
    ...mapState('Ensayos', ['form_data', 'data_ensayo', 'rangos']),
  },
  methods: {
    openmodal() {
      this.dialog = true;
      this.headers = [
        {
          text: i18n.t('ensayos.t_param'),
          align: 'start',
          sortable: false,
          value: 'nombre',
        },
        { text: i18n.t('ensayos.classifitacion'), value: 'cod_pat' },
        { text: i18n.t('ensayos.RiskLevel'), value: 'ncorto' },
        { text: i18n.t('ensayos.opciones'), value: 'data-table-expand' },
      ];
      // Variable que contiene el item seleccionado en el select tipoResultado
      // console.log(this.form_data.data_ensayo.tipoRes);
      // console.warn(this.form_data.data_ensayo);
      // this.rangos = JSON.stringify(this.form_data.parametrosedit);
      this.form_data.data_ensayo.parametrosCondicion = [];
      /// recorremos todos los parametros para dibujar los que se seleccionaron
      this.data_ensayo.parametros.forEach((element) => {
        if (this.form_data.data_ensayo.parametrosid.includes(element.cod_pat)) {
          const itemElemet = element;
          const relfil = this.form_data.data_ensayo.condicionParam.filter(
            (param) => param.patogeno_new_cod_pat === itemElemet.cod_pat,
          );
          if (relfil.length > 0) {
            itemElemet.nivel = relfil[0].nivel;
            itemElemet.clasificacionid = relfil[0].clasificacion;
          }
          this.form_data.data_ensayo.parametrosCondicion.push(itemElemet);
        }
      });

      // if(this.form_data.data_ensayo.tipoRes != 1) {
      //   this.cerrarExpandedRows();
      // }

      // console.log(this.headers);
    },
    Confirmanivel() {
      console.log(this.form_data.data_ensayo);
      // validamos los datos sean requeridos
      const specificacion = [];
      const rangos_requeridos = [];
      this.form_data.data_ensayo.parametrosCondicion.forEach((element) => {
        console.log('element validacion', element);
        if (!element.clasificacionid || typeof element.nivel === 'undefined') {
          specificacion.push(element.nombre);
        }
        if(this.form_data.data_ensayo.tipoRes == 1 && (!element.rangos || typeof element.rangos === 'undefined' || element.rangos[0].nu_rango_a == null || element.rangos[0].nu_potencia_a == null || element.rangos[0].nu_rango_b == null || element.rangos[0].nu_potencia_b == null)) {
          rangos_requeridos.push(element.nombre); 
        }
      });

      console.log('rangos_requeridos', rangos_requeridos);
      console.log('specificacion', specificacion);

      if (specificacion.length > 0) {
        this.snack.snackbar = true;
        this.snack.txt = i18n.t('ensayos.rules_txt_condicion');
      } else if(rangos_requeridos.length > 0) {
        this.snack.snackbar = true;
        this.snack.txt = i18n.t('ensayos.rules_txt_condicionparamrangos');
      } else {
        this.dialog = false;
      }
    },
    handleExpansion(item, state) {
      // console.log(item);
      // console.log(this.form_data.paramtrosEnsayo);
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item); // eslint-disable-line

      if (Object.keys(item).indexOf('rangos') === -1) {
        if(this.form_data.isNew == false) {
          let parametro = this.form_data.paramtrosEnsayo.filter((pr) => pr.nombre === item.nombre);
          console.log(parametro);

          if(parametro.length > 0) {
            item.rangos = (parametro[0].tb_pat_n_h_ensayos_rangos.length > 0) ? parametro[0].tb_pat_n_h_ensayos_rangos : [];
            item.rangos.forEach((i) => {
              console.log(i.id);
              i.borrado = 0;
            });
          } else {
            item.rangos = [{
              id: null,
              nu_rango_a: null,
              nu_potencia_a: null,
              nu_rango_b: null,
              nu_potencia_b: null,
              sn_grupo_rangos: null,
              borrado: null
            }];  
          }
        } else {
          item.rangos = [{
            id: null,
            nu_rango_a: null,
            nu_potencia_a: null,
            nu_rango_b: null,
            nu_potencia_b: null,
            sn_grupo_rangos: null,
            borrado: null
          }];
        }
      }
      // console.log('parametro', item.rangos);
    },
    cerrarExpandedRows() {
      this.expanded = [];
    },
    cancelar() {
      this.dialog = false;
      // this.renderTable = false;
      // // this.$emit('eventoAlerta');
      
      // this.$nextTick(() => {
        
      //   console.log('rangos modificados', this.form_data.data_ensayo.parametrosCondicion);
      //   this.form_data.data_ensayo.parametrosCondicion = {}; // vaciando la data
      //   console.log('refs', this.$refs);

      //   setTimeout(() => {
      //     this.form_data.data_ensayo.parametrosCondicion = JSON.parse(this.rangos);

      //     this.renderTable = true;
      //     this.dataReloaded++;

      //     console.log('refs', this.$refs);
      //     console.log('rangos modificados', this.form_data.data_ensayo.parametrosCondicion);
      //     console.log('contador', this.dataReloaded);
      //     console.log('rangos', JSON.parse(this.rangos));

      //   }, 1);
      // });
      
      // console.log('refs', this.$refs);

    }
  },
};


</script>
